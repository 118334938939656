import { HttpHeaders } from '@angular/common/http';

export class AppConstant {
  static httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  static apiGoogleKey = 'AIzaSyCTR66wZ1yfjZe-iJp_AoO4ew04GD9jl8k';
  static googleMapId = '3d954a6847f6db7';

  static center = { lat: 33.59661961288069, lng: -7.6168131153720875 };
  static zome = 20;
  static WEB_SERVICE_API_ERROR = 500;
  static INTERNAL_SERVER_ERROR = 999;
  static INVALID_MOBILE = 410;
  static MOBILE_ALREADY_USED = 411;
  static CIN_ALREADY_USED = 412;
  static LOGIN_ALREADY_USED = 413;
  static INVALID_USERNAME = 414;
  static USER_INACTIVATED = 415;
  static NOT_FOUND = 416;
  static ID_NULL = 417;
  static ID_INVALID = 418;
  static INVALID_PASSWORD_TYPE = 419;
  static ID_SHIPMENT_ALREADY_EXIST = 420;
  static AGENCY_NAME_ALREADY_EXIST = 421;
  static MODULE_ALREADY_EXIST = 422;
}
