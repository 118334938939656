import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ShelvingVM } from './models/shelving.model';
import { ShelvingService } from './shelving.service';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'milestone-shelving',
  templateUrl: './shelving.component.html',
  styleUrls: ['./shelving.component.scss'],
})
export class ShelvingComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  // private messageSubscription: Subscription | null = null; // Initialize with null

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'Shelving', field: 'idShelving' },
    { header: 'Capacity', field: 'capacity' },
    { header: 'Agency', field: 'agency' },
    { header: 'location', field: 'location' },
    { header: 'Created by', field: 'createdBy' },
    { header: 'Created date', field: 'createdDate' },
    { header: 'Last modified by', field: 'lastModifiedBy' },
    { header: 'Last modified date', field: 'lastModifiedDate' },
  ];

  shelving: ShelvingVM[] = [];
  dropdownFilters = [];

  constructor(
    private shelvingService: ShelvingService,
    private router: Router,
    private notification: NotificationService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.loadCities();

    this.layoutService.onMessageShelving().subscribe((module) => {
      console.log('onMessageShelving');
      this.loadCities();
    });
  }

  loadCities(): void {
    this.shelvingService.getShelvings().subscribe(
      (response) => {
        this.shelving = this.transformResponse(response.content);
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading shelving');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }

  private transformResponse(response: any[]): ShelvingVM[] {
    return response.map((item) => ({
      id: item.id,
      idShelving: item.idShelving,
      capacity: item.capacity,
      agency: item.agency ? item.agency.idAgency : null,
      location: item.location,
      createdBy: item.createdBy,
      createdDate: item.createdDate,
      lastModifiedBy: item.lastModifiedBy,
      lastModifiedDate: item.lastModifiedDate,
    }));
  }
}
