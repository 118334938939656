import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LocationComponent } from './modules/location/location.component';
import { LoginComponent } from './login/login/login.component';
import { AuthGuardService } from './core/config/auth.guard.service';
import { UsersComponent } from './modules/users_module/users/users.component';
import { LayoutComponent } from './layout/layout.component';
import { ZonesComponent } from './modules/zones/zones.component';
import { AgenciesComponent } from './modules/agencies/agencies.component';
import { CitiesComponent } from './modules/cities/cities.component';
import { SectorsComponent } from './modules/sectors/sectors.component';
import { PermissionsModuleComponent } from './modules/permissions-module/permissions-module.component';
import { PermissionsFunctionsComponent } from './modules/permissions-functions/permissions-functions.component';
import { UserTypesComponent } from './modules/user-types/user-types.component';
import { ShelvingComponent } from './modules/shelving/shelving.component';
import { CodeTrackingComponent } from './modules/code-tracking/code-tracking.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {PickupDetailsOrdreComponent}  from  './modules/pickup-operations/pickup-details-ordre/pickup-details-ordre.component';
import { ForgotpasswordModule } from './forgotpassword/forgotpassword.module';
import { PickupDetailsShipementComponent } from './modules/pickup-operations/pickup-details-shipement/pickup-details-shipement.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'location',
        component: LocationComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'zones',
        component: ZonesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'agencies',
        component: AgenciesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'cities',
        component: CitiesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'sectors',
        component: SectorsComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'modules',
        component: PermissionsModuleComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'functions',
        component: PermissionsFunctionsComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'profiles',
        loadChildren: () =>
          import('./modules/profiles/profiles.module').then(
            (m) => m.ProfilesModule
          ),
      },
      {
        path: 'user-types',
        component: UserTypesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'shelving',
        component: ShelvingComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'code-tracking',
        component: CodeTrackingComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'pickup-operations',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./modules/pickup-operations/pickup-operations.module').then(
            (m) => m.PickupOperationsModule
          ),
          
      },
      {
      path: 'pickup-operation-details/:idOrdre',
      canActivate: [AuthGuardService],
      component: PickupDetailsOrdreComponent,
      }
      ,
      {
        path: 'pickup-shipement-details/:idOrdre',
        canActivate: [AuthGuardService],
        component: PickupDetailsShipementComponent,
        }
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgotpassword/forgotpassword.module').then(
        (m) => m.ForgotpasswordModule
      ),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
