import { Component, OnInit } from '@angular/core';
import { Indicator } from '../../shared/indicator-card/indicator_card.model';

@Component({
  selector: 'milestone-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  pickupOrders: OrderVM[] = [];
  deliveryOrders: OrderVM[] = [];
  transportBacOrders: OrderVM[] = [];

  filteredPickupOrders: OrderVM[] = [];
  filteredDeliveryOrders: OrderVM[] = [];
  filteredTransportBacOrders: OrderVM[] = [];

  indicators: Indicator[] = [
    {
      title: 'Ramassages non affectés',
      value: '9 colis',
      icon: 'mdi:package-variant',
      titlesub1: 'Adresse',
      valuesub1: '1',
      titlesub2: 'Consigne',
      valuesub2: '0',
      backgroundClass: 'bg-light',
      backgroundIcon : 'bg-secondary',
    },
  ];

  ngOnInit(): void {
    this.generateFakeData();
    this.filteredPickupOrders = [...this.pickupOrders];
    this.filteredDeliveryOrders = [...this.deliveryOrders];
    this.filteredTransportBacOrders = [...this.transportBacOrders];
  }

  onGlobalSearchOperator(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase();
    console.log('Global Search Term:', searchTerm);
  }

  onGlobalSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase();

    this.filteredPickupOrders = this.pickupOrders.filter((order) =>
      this.matchesSearchTerm(order, searchTerm)
    );

    this.filteredDeliveryOrders = this.deliveryOrders.filter((order) =>
      this.matchesSearchTerm(order, searchTerm)
    );

    this.filteredTransportBacOrders = this.transportBacOrders.filter((order) =>
      this.matchesSearchTerm(order, searchTerm)
    );
  }

  matchesSearchTerm(order: OrderVM, searchTerm: string): boolean {
    return (
      order.date.toLowerCase().includes(searchTerm) ||
      order.time.toLowerCase().includes(searchTerm) ||
      order.type.toLowerCase().includes(searchTerm) ||
      order.reference.toLowerCase().includes(searchTerm) ||
      order.totalColis.toString().includes(searchTerm) ||
      order.operatorName.toLowerCase().includes(searchTerm) ||
      order.operatorPhone.toLowerCase().includes(searchTerm) ||
      order.status.toLowerCase().includes(searchTerm)
    );
  }

  generateFakeData(): void {
    // Generate some fake data for each table
    for (let i = 0; i < 5; i++) {
      this.pickupOrders.push({
        date: '27 Mar 2024',
        time: '15:23',
        type: 'Smart locker',
        reference: `32YZTW3${i}`,
        totalColis: 5,
        operatorName: 'Hedi zaz',
        operatorPhone: '+212 60000010',
        status: 'En attente d’affectation',
        statusClass: 'text-success-main', // Use different classes for different statuses
      });

      this.deliveryOrders.push({
        date: '28 Mar 2024',
        time: '10:00',
        type: 'Home Delivery',
        reference: `45HGTR${i}`,
        totalColis: 3,
        operatorName: 'Ahmed Slim',
        operatorPhone: '+212 60000020',
        status: 'Livré',
        statusClass: 'text-info-main', // Adjust the class based on the status
      });

      this.transportBacOrders.push({
        date: '29 Mar 2024',
        time: '12:30',
        type: 'Transport Bac',
        reference: `78JHGF${i}`,
        totalColis: 7,
        operatorName: 'Fatima Amr',
        operatorPhone: '+212 60000030',
        status: 'En cours',
        statusClass: 'text-warning-main', // Adjust the class based on the status
      });

      // Initialize the filtered lists
      this.filteredPickupOrders = [...this.pickupOrders];
      this.filteredDeliveryOrders = [...this.deliveryOrders];
      this.filteredTransportBacOrders = [...this.transportBacOrders];
    }
  }
}
