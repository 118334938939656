// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { CreateCodeTrackingVM } from './models/create_code_tracking_vm';
import {
  CodeTrackingPagenationsVM,
  CodeTrackingVM,
} from './models/code_tracking_vm';

@Injectable({
  providedIn: 'root',
})
export class CodeTrackingService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createCodeTracking(zone: CreateCodeTrackingVM): Observable<void> {
    const data = {
      codesTracking: zone.name,
      description: zone.description,
    };
    return this.http
      .post<CodeTrackingVM>(
        this.applicationConfigService.getEndpointFor('/dev/codeTracking'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getCodeTrakings(): Observable<CodeTrackingPagenationsVM> {
    return this.http
      .get<CodeTrackingPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/dev/codeTracking'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
