import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { Subject } from 'rxjs';
import { ApplicationConfigService } from './application-config.service';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { JwtToken } from 'src/app/login/model/jwt-token';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private stompClient: any;
  private messageSubject: Subject<any> = new Subject<any>();
  private locationSubject: Subject<any> = new Subject<any>();
  private isConnected: boolean = false;
  private readonly RECONNECT_DELAY = 5000;

  constructor(
    private applicationConfigService: ApplicationConfigService,
    private storageService: ClientDataStorageService
  ) {}

  init(): void {
    const jwtToken: JwtToken | null = this.storageService.getToken();
    const user = this.storageService.getUser();
    const socket = new SockJS(
      `${this.getWsUrl()}?token=${jwtToken?.accessToken}`
    ); // Pass token as a query parameter
    this.stompClient = Stomp.over(socket);
    this.stompClient;
    console.log(jwtToken?.accessToken);
    console.log(jwtToken?.refreshToken);

    console.log(this.stompClient);

    this.stompClient.connect(
      {
        Authorization: `Bearer ${jwtToken?.accessToken}`,
        userId: 'mile.stone',
      },
      (frame: any) => {
        console.log('WebSocket connected: ', frame);
        this.isConnected = true;

        // Add more detailed logging to confirm subscription
        this.stompClient.subscribe(
          `/user/${user.username}/queue/notifications`,
          (message: any) => {
            // console.log('Message received from WebSocket:', message);
            this.messageSubject.next(JSON.parse(message.body)); // Emit message to all subscribers
          }
        );

        // this.stompClient.subscribe('/topic/notifications', (message: any) => {
        //   console.log('Topic :', message);
        //   // this.messageSubject.next(JSON.parse(message.body)); // Emit message to all subscribers
        // });
      },
      (error: any) => this.onError(error)
    );
  }

  private getWsUrl(): string {
    // const url = this.applicationConfigService.getEndpointFor('');
    // console.log(url.split('/api')[0] + '/milestone-ws');

    // return url.split('/api')[0] + '/milestone-ws';
    return this.applicationConfigService.getEndpointWebSocket();
  }

  // Subscribe to the location updates of a specific user
  subscribeToUser(userId: string) {
    this.stompClient.subscribe(
      `/user/${userId}/queue/location`,
      (message: any) => {
        const data = JSON.parse(message.body);
        this.locationSubject.next(data);
      }
    );
  }

  sendMessage(destination: string, message: any): void {
    if (this.isConnected) {
      console.log('Sending message to: ', destination);

      this.stompClient.send(destination, {}, JSON.stringify(message));
    } else {
      console.error('Cannot send message, WebSocket is not connected.');
    }
  }

  onLocation(): Subject<any> {
    return this.locationSubject;
  }

  onMessage(): Subject<any> {
    return this.messageSubject;
  }

  private onError(error: any): void {
    console.error('WebSocket connection error: ', error);
    this.isConnected = false;
    setTimeout(() => this.init(), this.RECONNECT_DELAY); // Attempt reconnection after delay
  }
}
