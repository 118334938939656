import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { UserTypesService } from '../user-types.service';
import { CreateUserTypeVM } from '../models/create_user_types_vm';

@Component({
  selector: 'milestone-create-user-type-form',
  templateUrl: './create-user-type-form.component.html',
  styleUrls: ['./create-user-type-form.component.scss'],
})
export class CreateUserTypeFormComponent implements OnInit {
  userForm: FormGroup;

  createUserTypeError = false;
  errorUserType = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private userTypesService: UserTypesService
  ) {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      console.log('Form Submitted', this.userForm.value);
      this.loading = true;

      this.userTypesService
        .createUserTypes(
          new CreateUserTypeVM(
            this.userForm.value.name,
            this.userForm.value.description
          )
        )
        .subscribe(
          () => {
            this.notification.showMessageSuccess(
              'User Type created successfully'
            );
            this.loading = false;
            this.formSubmit.emit(this.userForm.value);
          },
          (error) => {
            this.createUserTypeError = true;
            this.errorUserType = error.error.message;
            this.loading = false;

            this.notification.showMessageError('Error creating user Type');
          }
        );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
