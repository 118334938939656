

<li class="tl-item ">
    <div class="bloc_tracking">                   
   <div class="item-title">
      <p>{{track.title_status}}</p>
      <span>{{track.description_status}}</span>
   </div>
   <div class="timestamp">
       <span>{{track.date_status}}</span>             
   </div>
</div>
</li>