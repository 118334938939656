import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgencyVM } from '../../agencies/models/agency.model';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AgenciesService } from '../../agencies/agencies.service';
import { ShelvingService } from '../shelving.service';
import { CreateShelvingVM } from '../models/create_shelving.model';

@Component({
  selector: 'milestone-create-shelving-form',
  templateUrl: './create-shelving-form.component.html',
  styleUrls: ['./create-shelving-form.component.scss'],
})
export class CreateShelvingFormComponent implements OnInit {
  userForm: FormGroup;
  createShelvingError = false;
  errorShelving = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  agencies: AgencyVM[] = [];

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private agenciesService: AgenciesService,
    private shelvingService: ShelvingService
  ) {
    this.userForm = this.fb.group({
      idShelving: ['', Validators.required],
      agency: ['', Validators.required],
      capacity: ['', Validators.required],
      location: ['', Validators.required],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadAgencies();
  }

  loadAgencies(): void {
    this.agenciesService.getAgencies().subscribe(
      (response) => {
        this.agencies = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading agencies');
      }
    );
  }

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      console.log('Form Submitted', this.userForm.value);
      this.loading = true;

      const newSector = new CreateShelvingVM(
        this.userForm.value.idShelving,
        this.userForm.value.agency,
        this.userForm.value.capacity,
        this.userForm.value.location
      );

      this.shelvingService.createShelving(newSector).subscribe(
        (response) => {
          console.log('response ', JSON.stringify(response));
          // this.notification.showMessageSuccess('Shelving created successfully');
          this.loading = false;
          this.formSubmit.emit(this.userForm.value);
        },
        (error) => {
          this.createShelvingError = true;
          this.errorShelving = error.error.message;
          this.loading = false;
          this.notification.showMessageError('Error creating sector');
        }
      );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  selectAgency(event: AgencyVM): void {
    this.userForm.patchValue({ agency: event.idAgency });
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
