<div class="row mt-2">
    <div class="col-md-9">
        <div class="card" style="border-left: 9px solid #385888;padding-top: 15px;">
            <div class="card-body">
                <div class="bloc_ordres">
               <div class="item_separ">
                <p class="title_details">Ref. Ordre:</p>
                <span class="value_details">{{idOrder}}</span>
              </div>
              <div class="item_separ">
                <div class="content_bloc">
                    <div>
                    <img src="assets/images/typedev.png" alt="">
                    </div>
                    <div>
                        <p class="title_details">Ramassage</p> 
                        <span class="value_details">{{infoshipement[0].ramassage}}</span>
                    </div>
                </div>
              </div>
              <div class="item_separ">
                <div class="content_bloc">
                    <div>
                    <img src="assets/images/Vector.png" alt="">
                    </div>
                    <div>
                        <p class="title_details">{{infoshipement[0].ramasseur}}</p> 
                        <span class="value_details">{{infoshipement[0].telramasseur}}</span>
                    </div>
                </div>
              </div>
              <div class="item_separ">
                <div class="content_bloc">
                    <div>
                    <img src="assets/images/livreur.png" alt="">
                    </div>
                    <div>
                        <p class="title_details">{{infoshipement[0].livreur}}</p> 
                        <span class="value_details">{{infoshipement[0].tellivreur}}</span>
                    </div>
                </div>
              </div>
    
              <div class="item_separ">
                <div class="content_bloc">
                    <div>
                    <img src="assets/images/box-solid.png" alt="">
                    </div>
                    <div>
                        <p class="title_details">Colis</p> 
                        <span class="value_details">{{infoshipement[0].nbrColis}}</span>
                    </div>
                </div>
              </div>
            </div>
            </div>
        </div>
    
    
       <div class="col-md-7 card p-3 mt-4 shadow-sm details_shipement" style="float:none;margin:auto;">
        <h5>Détails de l'expédition : <strong>{{ idOrder}}</strong></h5>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray">Type  livraison</span>
            <span class="text-clr">Domicile</span>
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Paiement à la livraison</span>
            <span class="text-clr">40 dh</span>    
        </div>
        <hr>

        <div class="d-flex align-items-center justify-content-between my-1 mt-2 mb-2">
            <span class="text-gray ">Trajet</span>
            <span class="text-clr">De Casablanca à Casablanca</span>    
        </div>
        <hr>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray">Taille du colis</span>
            <span class="text-clr">S</span>
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Ouverture du colis</span>
            <span class="text-clr">Oui</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Nature du colis</span>
            <span class="text-clr">Imprimés, ouvrages, documents</span>    
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Assurance</span>
            <span class="text-clr">Non</span>    
        </div>
        <hr>
        <h5>Expéditeur</h5>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Nom</span>
            <span class="text-clr">Hamza</span>    
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Prénom</span>
            <span class="text-clr">EL ALAOUI</span>    
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">E-mail</span>
            <span class="text-clr">Hamza.elalaoui&#64;gmail.com</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">GSM</span>
            <span class="text-clr">06.61.98.68.22</span>    
        </div>
        <hr>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Départ</span>
            <span class="text-clr">Casablanca</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">N° consigne</span>
            <span class="text-clr">1015</span>    
        </div>
        <hr>
        <h5>Destinataire</h5>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Nom</span>
            <span class="text-clr">Karim</span>    
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Prénom</span>
            <span class="text-clr">Ezzaim</span>    
        </div>

        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">E-mail</span>
            <span class="text-clr">karim&#64;gmail.com</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">GSM</span>
            <span class="text-clr">06.61.91.65.12</span>    
        </div>
        <hr>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Ville</span>
            <span class="text-clr">Casablanca</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">adresse</span>
            <span class="text-clr">18 avenue des FAR</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Quartier</span>
            <span class="text-clr">Habous</span>    
        </div>
        <div class="d-flex align-items-center justify-content-between my-1 mb-2">
            <span class="text-gray ">Secteur</span>
            <span class="text-clr">Belvédère - Habous</span>    
        </div>
       </div>
     
    
         
    
    </div>
    
    
    <div class="col-md-3">
        
        <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header >
                <span class="title_accord">Suivi de l'ordre</span>
              </mat-expansion-panel-header>
      
              <ng-template matExpansionPanelContent >
                <hr style="margin-top: -7px;">
                <div class="history-tl-container">
                <ul class="tl" style="width:100%">
                    <li  *ngFor="let track of tracking" class="tl-item {{track.title_status}}">
                         <div class="bloc_tracking">                   
                        <div class="item-title">
                           <p>{{track.title_status}}</p>
                           <span>{{track.description_status}}</span>
                        </div>
                        <div class="timestamp">
                            <span>{{track.date_status}}</span>             
                        </div>
                    </div>
                    </li>
                    
                    
                </ul>
                        </div>
              </ng-template>
            </mat-expansion-panel>
        
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <span class="title_accord">Messages associés</span>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <hr style="margin-top: -7px;">
              </ng-template>
            </mat-expansion-panel>
    
    
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <span class="title_accord">Documents</span>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <hr style="margin-top: -7px;">
              </ng-template>
            </mat-expansion-panel>
        
           
          </mat-accordion>
    </div>
    
     
    </div>
    