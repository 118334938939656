import { Component, Input } from '@angular/core';
import {tracking} from './tracking.model';

@Component({
  selector: 'milestone-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {
  @Input() track!: tracking;

  
}
