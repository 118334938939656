import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarService } from './sidebar.service';
import { ISidebar } from './sidebar.model';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/service/login.service';

@Component({
  selector: 'milestone-side-bare',
  templateUrl: './side-bare.component.html',
  styleUrls: ['./side-bare.component.scss'],
})
export class SideBareComponent implements OnInit {
  isVisible = false;

  menus: any = [];

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private sideBareService: SidebarService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.menus = this.sideBareService.getMenuList();
  }

  ngOnInit(): void {
    this.sideBareService.sidebarVisible$.subscribe((visible) => {
      this.isVisible = visible;
    });
  }

  toggleMenu(): void {
    console.log('toggleMenu >>> ');
    this.sideBareService.toggleSidebar();
  }

  toggle(currentMenu: any): void {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach((element: any) => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  handLinkCLick(sidebarItem: ISidebar): void {
    this.router.navigate([sidebarItem.routerLink], {
      queryParams: sidebarItem.queryParams,
    });
  }

  getState(currentMenu: any): any {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }
}
