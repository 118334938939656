import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private router: Router) {}
  moduleSource = new Subject<any>();
  private messagePicupSubject: Subject<any> = new Subject<any>();
  private messageShelvingSubject: Subject<any> = new Subject<any>();

  moduleS$ = this.moduleSource.asObservable();

  sendToModule(action: string): void {
    if (action === 'Picup') {
      this.messagePicupSubject.next('');
    }
    if (action === 'shelving') {
      this.messageShelvingSubject.next(action);
    }
  }

  onMessagePickup(): Subject<any> {
    return this.messagePicupSubject;
  }

  onMessageShelving(): Subject<any> {
    return this.messageShelvingSubject;
  }
}
