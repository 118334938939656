import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PolygonService } from '../../../shared/draw-polygon/draw-polygon.service';
import { AgenciesService } from '../../agencies/agencies.service';
import { AgencyVM } from '../../agencies/models/agency.model';
import { CitiesService } from '../../cities/cities.service';
import { CityVM } from '../../cities/models/city.model';
import { ZoneVM } from '../../zones/models/zone_vm';
import { ZoneService } from '../../zones/zones.service';
import { CreateSectorVM } from '../models/create_serctor.model';
import { SectorsService } from '../sectors.service';

@Component({
  selector: 'milestone-create-sector-from',
  templateUrl: './create-sector-from.component.html',
  styleUrls: ['./create-sector-from.component.scss'],
})
export class CreateSectorFromComponent implements OnInit {
  userForm: FormGroup;
  createSectorError = false;
  errorSector = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  zones: ZoneVM[] = [];
  agencies: AgencyVM[] = [];
  cities: CityVM[] = [];

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private polygonService: PolygonService,
    private citiesService: CitiesService,
    private zoneService: ZoneService,
    private agenciesService: AgenciesService,
    private sectorsService: SectorsService
  ) {
    this.userForm = this.fb.group({
      geom: ['', Validators.required],
      sector: ['', Validators.required],
      city: ['', Validators.required],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadCities();
    this.polygonService.polygon$.subscribe((points) => {
      this.userForm.patchValue({ geom: points });
    });
  }

  loadCities(): void {
    this.citiesService.getCities().subscribe(
      (response) => {
        this.cities = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading zones: ');
      }
    );
  }

  onSubmit(): void {
    console.log("userForm: ", this.userForm);
    if (this.userForm.valid && this.userForm.value.confirmation) {
      console.log('Form Submitted', this.userForm.value);
      this.loading = true;

      const newSector = new CreateSectorVM(
        this.userForm.value.sector,
        this.userForm.value.city,
        this.userForm.value.geom
      );

      this.sectorsService.createSector(newSector).subscribe(
        () => {
          this.notification.showMessageSuccess('Sector created successfully');
          this.loading = false;
          this.formSubmit.emit(this.userForm.value);
        },
        (error) => {
          this.createSectorError = true;
          this.errorSector = error.error.message;
          this.loading = false;
          this.notification.showMessageError('Error creating sector');
        }
      );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  selectCity(event: CityVM): void {
    this.userForm.patchValue({ city: event.idCity });
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
