import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { CitiesService } from '../cities.service';
import { CreateCityVM } from '../models/create_city.model';
import { ZoneVM } from '../../zones/models/zone_vm';
import { AgencyVM } from '../../agencies/models/agency.model';
import { ZoneService } from '../../zones/zones.service';
import { AgenciesService } from '../../agencies/agencies.service';

@Component({
  selector: 'milestone-create-city-from',
  templateUrl: './create-city-from.component.html',
  styleUrls: ['./create-city-from.component.scss'],
})
export class CreateCityFromComponent implements OnInit {
  userForm: FormGroup;
  createCityError = false;
  errorCity = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  zones: ZoneVM[] = [];
  agencies: AgencyVM[] = [];

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private citiesService: CitiesService,
    private zoneService: ZoneService,
    private agenciesService: AgenciesService
  ) {
    this.userForm = this.fb.group({
      idCity: ['', Validators.required],
      cityName: [''],
      agency: ['', Validators.required],
      zone: ['', Validators.required],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadZones();
    this.loadAgencies();
  }

  loadAgencies(): void {
    this.agenciesService.getAgencies().subscribe(
      (response) => {
        this.agencies = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading agencies');
      }
    );
  }

  loadZones(): void {
    this.zoneService.getZones().subscribe(
      (response) => {
        this.zones = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading zones');
      }
    );
  }

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      console.log('Form Submitted city', this.userForm.value);
      this.loading = true;
      const newCity = new CreateCityVM(
        this.userForm.value.idCity,
        this.userForm.value.cityName,
        this.userForm.value.agency,
        this.userForm.value.zone
      );

      this.citiesService.createCity(newCity).subscribe(
        () => {
          this.notification.showMessageSuccess('City created successfully');
          this.loading = false;
          this.formSubmit.emit(this.userForm.value);
        },
        (error) => {
          this.createCityError = true;
          this.errorCity = error.error.message;
          this.loading = false;
          this.notification.showMessageError('Error creating city');
        }
      );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  selectAgency(event: AgencyVM): void {
    this.userForm.patchValue({ agency: event.idAgency });
  }

  selectZone(event: ZoneVM): void {
    this.userForm.patchValue({ zone: event.zone });
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
