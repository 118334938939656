<aside class="sidebar" [class.sidebar-open]="isVisible">
  <div class="logo-side">
    <a routerLink="/dashboard">
      <img
        src="assets/milestone_logo.svg"
        alt="milestonelogo"
        class="light-logo"
      />
      <!-- <img src="assets/images/logo-light.png" alt="site logo" class="dark-logo" />
      <img src="assets/images/logo-icon.png" alt="site logo" class="logo-icon" /> -->
    </a>
  </div>
  <div class="sidebar-menu-area">
    <ul class="sidebar-menu no-padding-left" id="sidebar-menu">
      <li *ngFor="let menu of menus">
        <a
          *ngIf="menu.type === 'simple'"
          [routerLink]="menu.routerLink"
          routerLinkActive="active"
        >
          <img [src]="menu.icon" alt="icon" class="menu-icon" />
          <span>{{ menu.title }}</span>
        </a>
        <div *ngIf="menu.type === 'dropdown'">
          <a style="cursor: pointer;"><img [src]="menu.icon" alt="icon" class="menu-icon" />
          <span (click)="menu.isOpen = !menu.isOpen">
            {{ menu.title }}
          </span></a>
          <ul *ngIf="menu.isOpen && menu.submenus && menu.submenus.length">
            <li *ngFor="let submenu of menu.submenus">
              <a [routerLink]="submenu.routerLink" routerLinkActive="active">
                <img [src]="submenu.icon" alt="icon" class="menu-icon" />
                <span>{{ submenu.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</aside>
