import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

@Pipe({
  name: 'formatMediumDatetime',
})
// https://day.js.org/docs/en/display/format
export class FormatMediumDatetimePipe implements PipeTransform {
  transform(val: any): string {
    if(val){
      const strDate = `${String(val[1])}-${String(val[2])}-${String(val[0])} ${String(val[3])}:${String(val[4])}:${String(val[5])}`;
      const parsedDate = dayjs(strDate, 'MM-DD-YYYY HH:mm:ss');
      return parsedDate.format('D MMM YYYY [à] HH:mm:ss');    
    }
    
    return '';
    }
}
