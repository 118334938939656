import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { JwtToken } from '../model/jwt-token';
import { LoginVM } from '../model/login-vm';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  login(credential: LoginVM): Observable<JwtToken> {
    const data = {
      username: credential.username,
      password: credential.password,
    };
    return this.http
      .post<JwtToken>(
        this.applicationConfigService.getEndpointFor('/authenticate'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => this.authenticateSuccess(response, credential.rememberMe)));
  }

  logout(): void {
   // this.http.get(this.applicationConfigService.getEndpointFor('/logout')).subscribe(() => console.log("logout http"));
      console.log("observer logout");      
      this.storageService.signOut();

  }

  isAutenticated(): Observable<boolean> {
    return this.http
      .get(this.applicationConfigService.getEndpointFor('/isAuthenticated'))
      .pipe(
        map((auth) => auth as boolean), // Cast to boolean
        catchError(() => of(false)) // Return false if error
      );
    // return this.storageService.isAutenticated();
  }

  refreshToken(): Observable<JwtToken> {
   return this.http.get<JwtToken>(this.applicationConfigService.getEndpointFor('/refresh-token'),  AppConstant.httpOptions)
   .pipe(map((response) => this.authenticateSuccess(response)));
  }

  private authenticateSuccess(response: JwtToken, rememberMe?: boolean): JwtToken {
    if(rememberMe == null){
      rememberMe=false
    }
    this.storageService.saveToken(response, rememberMe);
    this.storageService.setAuthenticated("true")
    return response;
  }
}
