import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { CodeTrackingVM } from './models/code_tracking_vm';
import { CodeTrackingService } from './code-trakcing.service';

@Component({
  selector: 'milestone-code-tracking',
  templateUrl: './code-tracking.component.html',
  styleUrls: ['./code-tracking.component.scss'],
})
export class CodeTrackingComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'CodesTracking', field: 'codesTracking' },
    { header: 'Description', field: 'description' },
    { header: 'Created by', field: 'createdBy' },
  ];

  codeTrackings: CodeTrackingVM[] = [];

  dropdownFilters = [];

  constructor(
    private codeTrackingService: CodeTrackingService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadCodeTracking();
  }

  loadCodeTracking(): void {
    this.codeTrackingService.getCodeTrakings().subscribe(
      (response) => {
        this.codeTrackings = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading codeTrackings');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted code tracking', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }
}
