import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { PickupOperationsService } from '../services/pickup-operations.service';
import { tracking } from 'src/app/shared/tracking/tracking.model';
import { FormBuilder } from '@angular/forms';
import { Pickup } from '../pickup.model';
import { NotificationService } from 'src/app/shared/notification/notification.service';

import $ from "jquery";
@Component({
  selector: 'milestone-pickup-details-ordre',
  templateUrl: './pickup-details-ordre.component.html',
  styleUrls: ['./pickup-details-ordre.component.scss']
})
export class PickupDetailsOrdreComponent implements OnInit{
  idOrder = '';

  users: any[] = [];
  page= 0;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 20, 30];
  pickup?: Pickup;

  columns = [
    { header: 'Date', field: 'date' },
    { header: 'Référence dexpédition', field: 'ref_ordre' },
    { header: 'Ville de départ', field: 'ville_depart' },
    { header: 'Ville d\'arrivée', field: 'ville_arrivee' },
    { header: 'Client', field: 'client' },
    { header: 'Statut', field: 'statut' },
    
  ];

  tracking: tracking[] = [
    { title_status: 'Ordre de ramassage créé', 
      description_status: 'Ordre en attente d\'affectation',
       date_status:  '03/20/2021  10:06'},
    { title_status: 'Ordre de ramassage créé', 
    description_status: 'Ordre en attente d\'affectation',
      date_status:  '03/20/2021  10:06'},   
    { title_status: 'Incident', 
      description_status: 'Client injoignable',
       date_status: '03/20/2021  10:06' },

  ];

  dateRange = this.fb.group({
    start: [null],
    end: [null]
  })

  constructor(private route:ActivatedRoute,
     private pickupOperationService: PickupOperationsService, 
     private fb:FormBuilder,
     private notification: NotificationService ) {}


  ngOnInit():void {

    const id = this.route.snapshot.paramMap.get('idOrdre') ?? '';
    this.idOrder=id;
    this.getorderByuserfile(this.idOrder);
    this.fetchPosts();
    console.log(this.columns);

    $('.mat-mdc-text-field-wrapper').css({
      'background' : 'white',
      'height' : '46px'
   });
  }


  getorderByuserfile(idOrder:any):void{
    console.log(idOrder);
    this.pickupOperationService.getOrdreByUserfile(idOrder)
      .subscribe((response: any) => {
        this.pickup = response;          
      });
  }


  fetchPosts(): void {
    this.pickupOperationService
      .getUsers(this.page, this.tableSize)
      .subscribe((response: any) => {
        console.log(response);
        this.users = response.data;
        this.count = response.total;
      });
  }

  onTableDataChange(event: any): void {
    console.log(event.pageSize);
    this.page = event.pageIndex;
    this.fetchPosts();
  }

  handlePageSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.onTableDataChange(0);
    this.page=1;
    this.fetchPosts();
  }
}
