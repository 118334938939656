// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { CreateZoneVM } from './models/create_zone_vm';
import { ZonePagenationsVM, ZoneVM } from './models/zone_vm';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createZone(zone: CreateZoneVM): Observable<void> {
    const data = {
      zone: zone.name,
      description: zone.description,
    };
    return this.http
      .post<ZoneVM>(
        this.applicationConfigService.getEndpointFor('/admin/zones'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getZones(): Observable<ZonePagenationsVM> {
    return this.http
      .get<ZonePagenationsVM>(
        this.applicationConfigService.getEndpointFor('/zones/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
