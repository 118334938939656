import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { Router } from '@angular/router';
import { PermissionsFunctionsVM } from './models/permission_functions_vm';
import { PermissionFunctionsService } from './permissions-functions.service';



@Component({
  selector: 'milestone-permissions-functions',
  templateUrl: './permissions-functions.component.html',
  styleUrls: ['./permissions-functions.component.scss']
})
export class PermissionsFunctionsComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'Function', field: 'functions' },
    { header: 'Module', field: 'modules' },
    { header: 'Description', field: 'description' },
    { header: 'Created by', field: 'createdBy' },
    { header: 'Created date', field: 'createdDate' },
    { header: 'Last modified by', field: 'lastModifiedBy' },
    { header: 'Last modified date', field: 'lastModifiedDate' },
  ];


  permissionFunctions: PermissionsFunctionsVM[] = [];
  dropdownFilters = [];

  constructor(
    private permissionFunctionsService: PermissionFunctionsService,  
    private router: Router,
    private notification: NotificationService,
  ) {}


  ngOnInit(): void {
    this.loadSectors();
  }

  loadSectors(): void {
    this.permissionFunctionsService.getPermissionFunctions().subscribe(
      (response) => {
        this.permissionFunctions = this.transformResponse(  response.content);
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading permissionFunctions');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }
  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }


  private transformResponse(response: any[]): PermissionsFunctionsVM[] {
    return response.map(item => ({
      id: item.id,
      functions: item.functions,
      description: item.description,
      modules: item.module ? item.module.modules : null,
      createdBy: item.createdBy,
      createdDate: item.createdDate,
      lastModifiedBy: item.lastModifiedBy,
      lastModifiedDate: item.lastModifiedDate,
    }));
  }
  
}