// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateSectorVM } from './models/create_serctor.model';
import { SectorPagenationsVM, SectorVM } from './models/sector.model';

@Injectable({
  providedIn: 'root',
})
export class SectorsService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createSector(sector: CreateSectorVM): Observable<void> {
    console.log('sector', sector);

    const data = {
      sector: sector.sector,
      city: { idCity: sector.city },
      geom: sector.geom,
    };
    return this.http
      .post<SectorVM>(
        this.applicationConfigService.getEndpointFor('/managers/sectors'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getSectors(): Observable<SectorPagenationsVM> {
    return this.http
      .get<SectorPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/sectors/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
