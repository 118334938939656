import { Component, Input } from '@angular/core';
import { Indicator } from './indicator_card.model';

@Component({
  selector: 'milestone-indicator-card',
  templateUrl: './indicator-card.component.html',
  styleUrls: ['./indicator-card.component.scss'],
})
export class IndicatorCardComponent {
  @Input() indicator!: Indicator;
}
