<div class="row mt-2">
<div class="col-md-9">
    <div class="card" style="border-left: 9px solid #385888;padding-top: 15px;">
        <div class="card-body">
            <div class="bloc_ordres">
           <div class="item_separ">
            <p class="title_details">Ref. Ordre:</p>
            <span class="value_details">{{idOrder}}</span>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/typedev.png" alt="">
                </div>
                <div>
                    <p class="title_details">Ramassage</p> 
                    <span class="value_details">25 Boulevard Ziraoui, Bourgogne, Casablanca</span>
                </div>
            </div>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/Vector.png" alt="">
                </div>
                <div>
                    <p class="title_details">Karim Ezzaim</p> 
                    <span class="value_details">06 08 08 05 07</span>
                </div>
            </div>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/livreur.png" alt="">
                </div>
                <div>
                    <p class="title_details">Hedi ZAZ</p> 
                    <span class="value_details">06 08 08 05 08</span>
                </div>
            </div>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/box-solid.png" alt="">
                </div>
                <div>
                    <p class="title_details">Colis</p> 
                    <span class="value_details">{{pickup?.countParcel}}</span>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>

    <div class="filter_ordre_details mt-8">

      <div>
      <mat-form-field appearance="fill">
        <mat-label>Choisir une date</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Date de début">
          <input matEndDate formControlName="end" placeholder="Date de fin">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <button>today</button>
        </mat-date-range-picker>
      </mat-form-field>
      </div>
      
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="refOrdre" placeholder="Réf. Ordre">
        <i class="fas fa-search"></i>
      </div>
      </div>

      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="ville_depart" placeholder="Ville de départ">
        <i class="fas fa-search"></i>
      </div>
      </div>
      
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="ville_depart" placeholder="Ville d'arrivée">
        <i class="fas fa-search"></i>
      </div>
      </div>
     
      
      <!-- Point de Livraison -->
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="client" placeholder="Client">
        <i class="fas fa-search"></i>
        </div>
      </div>
      
      

      
      <!-- Statut -->
      <div>
        <select class="form-select" id="statut">
            <option selected>Statut</option>
            <option value="1">En cours</option>
            <option value="2">Livré</option>
            <option value="3">Annulé</option>
        </select>
      </div>
      
      
      </div>
   

    <table class="tbl table " style="margin-top: 15px;">
        <thead>
        <tr>
            <th *ngFor="let column of columns ; let i = index" class="{{ column.field }}">{{ column.header }}</th>   
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users| paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }">
            <td class="date_creation"><a [routerLink]="['/pickup-shipement-details', user.id ]">{{ user.id }}</a></td>
            <td class="ref_ordre">{{ user.first_name }}</td>
            <td class="secteur">{{ user.last_name }}</td>
            <td class="quartier">{{ user.email }}</td>
            <td class="ville">{{ user.first_name }}</td>
            <td class="statut">{{ user.email }}</td>
        </tr>
      </tbody>
      </table>  

      <div class="d-flex  flex-row-reverse">
        <div>
      
          <mat-paginator class="parcel-mat-paginator" #paginator [length]="count"
                    [pageIndex]="0"
                    [pageSize]="tableSize"
                    [hidePageSize]="true"
                    (page)="onTableDataChange($event)" >
      </mat-paginator>
        </div>

        <div class="col-md-2">
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-7 col-form-label" style="font-weight: 300;">Lignes par page:</label>
            <div class="col-sm-5">
              <select class="form-select sort" (change)="handlePageSizeChange($event)" style="height:40px">
                <option *ngFor="let size of tableSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

</div>


<div class="col-md-3">
    
    <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header >
            <span class="title_accord">Suivi de l'ordre</span>
          </mat-expansion-panel-header>
  
          <ng-template matExpansionPanelContent >
            <hr style="margin-top: -7px;">
            <div class="history-tl-container">
            <ul class="tl" style="width:100%">
                <li  *ngFor="let track of tracking" class="tl-item {{track.title_status}}">
                     <div class="bloc_tracking">                   
                    <div class="item-title">
                       <p>{{track.title_status}}</p>
                       <span>{{track.description_status}}</span>
                    </div>
                    <div class="timestamp">
                        <span>{{track.date_status}}</span>             
                    </div>
                </div>
                </li>
                
                
            </ul>
                    </div>
          </ng-template>
        </mat-expansion-panel>
    
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="title_accord">Messages associés</span>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <hr style="margin-top: -7px;">
          </ng-template>
        </mat-expansion-panel>


        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="title_accord">Documents</span>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <hr style="margin-top: -7px;">
          </ng-template>
        </mat-expansion-panel>
    
       
      </mat-accordion>
</div>

 
</div>
