
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { AgencyPagenationsVM, AgencyVM } from './models/agency.model';
import { CreateAgencyVM } from './models/create_agency.model';

@Injectable({
  providedIn: 'root',
})
export class AgenciesService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createAgency(agency: CreateAgencyVM): Observable<void> {
    const data = {
      idAgency: agency.idAgency,
      agencyName: agency.agencyName,
      description: agency.description,
      addressAgency: agency.addressAgency,
      position: agency.position,
    };
    return this.http
      .post<AgencyVM>(
        this.applicationConfigService.getEndpointFor('/admin/agencies'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getAgencies(): Observable<AgencyPagenationsVM> {
    return this.http
      .post<AgencyPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/agencies/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
