import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

@Pipe({
  name: 'formatMediumDate',
})
export class FormatMediumDatePipe implements PipeTransform {
  transform(val: any): string {
   if(val){
      const parsedDate = dayjs(`${String(val[1])}-${String(val[2])}-${String(val[0])}`, 'MM-DD-YYYY');
      return parsedDate.format('D MMM YYYY');
    }    
    return '';
  }
}
