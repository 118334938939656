import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { tracking } from 'src/app/shared/tracking/tracking.model';

@Component({
  selector: 'milestone-pickup-details-shipement',
  templateUrl: './pickup-details-shipement.component.html',
  styleUrls: ['./pickup-details-shipement.component.scss']
})
export class PickupDetailsShipementComponent implements OnInit {
  idOrder = '';

  tracking: tracking[] = [
    { title_status: 'Ordre de ramassage créé', 
      description_status: 'Ordre en attente d\'affectation',
       date_status:  '03/20/2021  10:06'},
    { title_status: 'Ordre de ramassage créé', 
    description_status: 'Ordre en attente d\'affectation',
      date_status:  '03/20/2021  10:06'},   
  ];

  infoshipement = [
    {
      refordre: 4,
      ramassage: '25 Boulevard Ziraoui, Bourgogne, Casablanca',
      ramasseur: 'Karime zaim',
      telramasseur: '06.45.87.95.60',
      livreur : 'Hedi ZAZ',
      tellivreur: '06.45.87.95.80',
      nbrColis : '4'
    }
  ];

  constructor(private route:ActivatedRoute) {}

  ngOnInit():void{
    const id = this.route.snapshot.paramMap.get('idOrdre') ?? '';
    this.idOrder=id;

  }
}
