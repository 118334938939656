// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { CreateUserTypeVM } from './models/create_user_types_vm';
import { UserTypesPagenationsVM, UserTypesVM } from './models/user_types_vm';

@Injectable({
  providedIn: 'root',
})
export class UserTypesService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createUserTypes(usertype: CreateUserTypeVM): Observable<void> {
    const data = {
      usertype: usertype.name,
      description: usertype.description,
    };
    return this.http
      .post<UserTypesVM>(
        this.applicationConfigService.getEndpointFor('/admin/userTypes'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getUserTypes(): Observable<UserTypesPagenationsVM> {
    return this.http
      .get<UserTypesPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/userTypes/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
