import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  Observable,
  catchError,
  lastValueFrom,
  map,
  tap,
  throwError,
} from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { UserType } from './User-type.enum';
import { AccountUser } from './account-model';
import { AccountUserPagenationsVM } from './account-paggination';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentUser: AccountUser | null = null;

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService,
    private dataStorage: ClientDataStorageService,
    private notification: NotificationService
  ) {}

  getUserDetails(): Promise<AccountUser> {
    return lastValueFrom(
      this.http
        .get<AccountUser>(
          this.applicationService.getEndpointFor('/users/account'),
          AppConstant.httpOptions
        )
        .pipe(
          tap((usr: AccountUser) => {
            this.currentUser = usr;
            this.dataStorage.saveUser(usr);
            console.log('getUserDetails ----');
          }),
          catchError((err) => {
            console.error('Error:', JSON.stringify(err));
            this.notification.showMessageError(err.message);
            // Re-throw the error to propagate it up the observable chain
            return throwError(err);
          })
        )
    );
  }

  getAllUsers(): Observable<AccountUser[]> {
    return this.http
      .get<AccountUserPagenationsVM>(
        this.applicationService.getEndpointFor('/admin/users/query'),
        AppConstant.httpOptions
      )
      .pipe(
        map((accounts) => this.convertDateArrayFromServer(accounts.content))
      );
  }

  getUsersByFirstnameOrLastname(name: string): Observable<AccountUser[]> {
    return this.http
      .get<AccountUser[]>(
        this.applicationService.getEndpointFor(`/users/${name}`),
        AppConstant.httpOptions
      )
      .pipe(map((accounts) => this.convertDateArrayFromServer(accounts)));
  }

  getAllOperators(): Observable<AccountUser[]> {
    return this.http
      .get<AccountUser[]>(
        this.applicationService.getEndpointFor(`/admin/users/all`),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => this.convertDateArrayFromServer(resp)));
  }

  getAllOperatorsByName(name: string): Observable<AccountUser[]> {
    return this.http
      .get<AccountUser[]>(
        this.applicationService.getEndpointFor(
          `/users/userType-name/${UserType.OPERATOR}/${name}`
        ),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => this.convertDateArrayFromServer(resp)));
  }

  hasAnyAuthority(rights: string[] | string): boolean {
    if (!this.currentUser) {
      return false;
    }
    if (!Array.isArray(rights)) {
      rights = [rights];
    }
    return this.currentUser
      .getfunctions()
      .some((right: string) => rights.includes(right));
  }

  protected convertDateFromServer(res: AccountUser): AccountUser {
    res.createdDate = res.createdDate ? dayjs(res.createdDate) : undefined;
    res.lastModifiedDate = res.lastModifiedDate
      ? dayjs(res.lastModifiedDate)
      : undefined;

    return res;
  }

  protected convertDateArrayFromServer(res: AccountUser[]): AccountUser[] {
    res.forEach((data: AccountUser) => {
      data.createdDate = data.createdDate ? dayjs(data.createdDate) : undefined;
      data.lastModifiedDate = data.lastModifiedDate
        ? dayjs(data.lastModifiedDate)
        : undefined;
    });

    return res;
  }
}
