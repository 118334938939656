<div>
  <!-- title start -->
  <div class="d-flex m-0 mb-4 ml-4 align-items-center justify-content-between">
    <div class="h4 mb-0 font-weight-bold" style="margin: 5rem">
      <img src="assets/content/images/title/location.svg" width="30" />
      <span>Positions</span>
    </div>
  </div>

  <!-- title end -->

  <button mat-flat-button (click)="updatePosition()" color="warn">
    Update Position
  </button>

  <div class="row p-2 mb-5">
    <div class="col-sm min-vh-60 map-container brd-red-1">
      <!-- Google Map Integration -->
      <google-map
        id="map"
        [options]="mapOptions"
        [center]="center"
        [zoom]="zoom"
        [style]="{ width: '100%', height: '100%' }"
        (mapClick)="moveMap($event)"
        (zoomChanged)="onZoom()"
        #googleMap
      >
      </google-map>
    </div>
  </div>
</div>
