<div class="col">
  <div class="card shadow-none border {{ indicator.backgroundClass }} h-100">
    <div class="card-body p-20">
      <div
        class="d-flex flex-wrap align-items-center justify-content-between gap-3"
      >
        <div>
          <p class="fw-medium text-primary-light mb-1">{{ indicator.title }}</p>
          <div class="items-icon">
            <div
              class="w-40-px h-40-px {{ indicator.backgroundIcon }} d-flex justify-content-center align-items-center mr-16"
            >
              <iconify-icon
                [icon]="indicator.icon"
                class="text-white text-2xl mb-0 mx-16"
              ></iconify-icon>
            </div>
            <h6 class="mb-0 mb-0 mx-10">{{ indicator.value }}</h6>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3 align-items-center">
        <div>
          <p class="mb-0">{{ indicator.valuesub1 }}</p>

          <p class="fw-medium text-muted mb-1">{{ indicator.titlesub1 }}</p>
        </div>

        <div class="h-24-px w-5-px shadow-none border"></div>

        <div>
          <p class="mb-0">{{ indicator.valuesub2 }}</p>

          <p class="fw-medium text-muted mb-1">{{ indicator.titlesub2 }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
